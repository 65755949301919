var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "PageView",
    {
      staticClass: "CaseEventsDetailView d-flex flex-column flex-fill",
      attrs: { "data-test-id": "case_event_detail-view" },
    },
    [
      _vm.status.LOADING ? _c("ui-loader") : _vm._e(),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: !_vm.status.LOADING && _vm.areInputsReady,
              expression: "!status.LOADING && areInputsReady",
            },
          ],
        },
        [
          _c("div", { staticClass: "row pb-2" }, [
            _c(
              "div",
              {
                staticClass:
                  "col-12 d-flex align-items-center justify-content-between",
              },
              [
                _c(
                  "h2",
                  { staticClass: "emobg-font-weight-bold emobg-color-ink" },
                  [_vm._v(" " + _vm._s(_vm.title) + " ")]
                ),
                _c(
                  "Actions",
                  [
                    _c(
                      "CopyToClipboardComponent",
                      _vm._b(
                        { staticClass: "p-2 my-2" },
                        "CopyToClipboardComponent",
                        _vm.actionsComponentProps,
                        false
                      )
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
          _c("ui-card", { staticClass: "d-flex flex-column flex-fill" }, [
            _c("div", { staticClass: "flex-fill m-n3" }, [
              _c("div", { staticClass: "d-flex flex-wrap" }, [
                _c(
                  "div",
                  { staticClass: "col-12 col-md-7 p-3" },
                  [
                    _c("h2", { staticClass: "emobg-font-weight-bold" }, [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$t("Common.Descriptions.details_element", {
                              element: _vm.upperFirst(
                                _vm.$t("Common.Business.case")
                              ),
                            })
                          ) +
                          " "
                      ),
                    ]),
                    _c("hr", {
                      staticClass:
                        "emobg-border-top-2 emobg-border-color-ground-light my-3",
                    }),
                    _vm.caseEvent.entityModified
                      ? _c(
                          "ui-alert",
                          {
                            staticClass: "d-block mb-4 w-100",
                            attrs: {
                              color: _vm.COLORS.warning,
                              icon: _vm.ICONS.alertFull,
                            },
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$t(
                                    "CasesServices.Common.user_status_updated"
                                  )
                                ) +
                                " "
                            ),
                          ]
                        )
                      : _vm._e(),
                    _c("div", { staticClass: "row" }, [
                      _vm.caseEvent.booking
                        ? _c(
                            "div",
                            { staticClass: "col-12 col-lg-4 pb-4" },
                            [
                              _c(
                                "label",
                                {
                                  staticClass:
                                    "d-block emobg-font-small pl-1 pb-1 emobg-font-weight-bold emobg-color-ink",
                                },
                                [_vm._v(" Booking number ")]
                              ),
                              _c(
                                "PermissionLink",
                                {
                                  staticClass:
                                    "emobg-link-primary emobg-body-2",
                                  attrs: {
                                    "link-permissions": [
                                      _vm.CARSHARING_PERMISSIONS
                                        .viewCarsharingBookings,
                                    ],
                                    to: {
                                      name: _vm.carsharingRoutes.bookings.detail
                                        .index,
                                      params: {
                                        bookingUuid: _vm.get(
                                          _vm.caseEvent,
                                          "booking.uuid"
                                        ),
                                      },
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    " #" +
                                      _vm._s(
                                        _vm.get(_vm.caseEvent, "booking.id")
                                      ) +
                                      " "
                                  ),
                                ]
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.caseEvent.user
                        ? _c(
                            "div",
                            { staticClass: "col-12 col-lg-4 pb-4" },
                            [
                              _c(
                                "label",
                                {
                                  staticClass:
                                    "d-block emobg-font-small pl-1 pb-1 emobg-font-weight-bold emobg-color-ink",
                                },
                                [_vm._v(" User ")]
                              ),
                              _c(
                                "PermissionLink",
                                {
                                  staticClass:
                                    "emobg-link-primary emobg-body-2",
                                  attrs: {
                                    "link-permissions": [
                                      _vm.CRM_PERMISSIONS.viewCrmUsers,
                                    ],
                                    to: {
                                      name: _vm.crmRoutes.users.detail.index,
                                      params: {
                                        userUuid: _vm.get(
                                          _vm.caseEvent,
                                          "user.uuid"
                                        ),
                                      },
                                    },
                                    target: "_blank",
                                  },
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.get(_vm.caseEvent, "user.firstName")
                                      ) +
                                      " " +
                                      _vm._s(
                                        _vm.get(_vm.caseEvent, "user.lastName")
                                      ) +
                                      " "
                                  ),
                                ]
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.caseEvent.vehicle
                        ? _c(
                            "div",
                            { staticClass: "col-12 col-lg-4 pb-4" },
                            [
                              _c(
                                "label",
                                {
                                  staticClass:
                                    "d-block pb-1 emobg-body-2 emobg-color-ink",
                                },
                                [_vm._v(" Vehicle ")]
                              ),
                              _c(
                                "PermissionLink",
                                {
                                  staticClass:
                                    "emobg-link-primary emobg-body-2",
                                  attrs: {
                                    "link-permissions": [
                                      _vm.CARSHARING_PERMISSIONS
                                        .viewCarsharingVehicles,
                                    ],
                                    to: {
                                      name: _vm.fleetRoutes.vehicles.detail
                                        .details,
                                      params: {
                                        vehicle_uuid: _vm.get(
                                          _vm.caseEvent,
                                          "vehicle.uuid"
                                        ),
                                      },
                                    },
                                    target: "_blank",
                                  },
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.get(
                                          _vm.caseEvent,
                                          "vehicle.licensePlate"
                                        )
                                      ) +
                                      " "
                                  ),
                                ]
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ]),
                    _vm.areInputsReady
                      ? _c("MuiAlgoliaSelect", {
                          staticClass: "w-100 pb-4",
                          attrs: {
                            title: (result) =>
                              `${_vm.sentenceCase(result.internal_name)}`,
                            filters: _vm.filterCaseTypes,
                            "results-per-page": 50,
                            disabled:
                              _vm.get(
                                _vm.caseEvent,
                                "reportingUser.id",
                                null
                              ) === 1 && _vm.isEditing,
                            index: _vm.ALGOLIA_INDEXES.caseTypes,
                            name: "case_type_uuid",
                            label:
                              _vm.$t("Common.Descriptions.type_element", {
                                element: _vm.upperFirst(
                                  _vm.$t("Common.Business.case")
                                ),
                              }) + "*",
                            placeholder: _vm.$t(
                              "Common.Actions.select_element",
                              {
                                element: _vm.$t(
                                  "Common.Descriptions.type_element",
                                  {
                                    element: _vm.upperFirst(
                                      _vm.$t("Common.Business.case")
                                    ),
                                  }
                                ),
                              }
                            ),
                            "path-value": "uuid",
                          },
                          model: {
                            value: _vm.inputs.caseTypeUuid,
                            callback: function ($$v) {
                              _vm.$set(_vm.inputs, "caseTypeUuid", $$v)
                            },
                            expression: "inputs.caseTypeUuid",
                          },
                        })
                      : _vm._e(),
                    !_vm.isEditing
                      ? _c("ui-text-area", {
                          staticClass: "Ui-TextArea--h-auto w-100",
                          attrs: {
                            value: _vm.inputs.comment,
                            label: "Description",
                            name: "description",
                            rows: "7",
                          },
                          on: {
                            changevalue: (event) =>
                              (_vm.inputs.comment = event.detail),
                          },
                        })
                      : _c(
                          "ContentCellComponent",
                          { attrs: { label: "Description" } },
                          [
                            _c(
                              "div",
                              { staticStyle: { "white-space": "pre-wrap" } },
                              [_vm._v(" " + _vm._s(_vm.inputs.comment) + " ")]
                            ),
                          ]
                        ),
                    _c("hr", {
                      staticClass:
                        "emobg-border-top-2 emobg-border-color-ground-light my-4",
                    }),
                    _c(
                      "label",
                      {
                        staticClass:
                          "d-block emobg-font-small pl-1 pb-1 emobg-font-weight-bold emobg-color-ink",
                      },
                      [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.upperFirst(
                                _vm.$t("Common.Comparisons.relates_to")
                              )
                            ) +
                            " "
                        ),
                      ]
                    ),
                    _vm.areInputsReady
                      ? _c("RelatesToComponent", {
                          model: {
                            value: _vm.inputs.linkedEntities,
                            callback: function ($$v) {
                              _vm.$set(_vm.inputs, "linkedEntities", $$v)
                            },
                            expression: "inputs.linkedEntities",
                          },
                        })
                      : _vm._e(),
                    _c("hr", {
                      staticClass:
                        "emobg-border-top-2 emobg-border-color-ground-light my-4",
                    }),
                    _c(
                      "label",
                      {
                        staticClass:
                          "d-block emobg-font-small pl-1 pb-1 emobg-font-weight-bold emobg-color-ink",
                        attrs: { for: "fileUploader" },
                      },
                      [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.upperFirst(
                                _vm.$t("Common.Descriptions.attachments")
                              )
                            ) +
                            " "
                        ),
                      ]
                    ),
                    _c("CaseFileUploaderComponent", {
                      attrs: {
                        id: "fileUploader",
                        "on-change": _vm.onChangeImage,
                        accept:
                          "image/png,image/jpg,image/jpeg,application/pdf",
                      },
                    }),
                    _vm._l(_vm.inputs.caseFiles, function (file, fileIndex) {
                      return _c(
                        "ul",
                        {
                          key: `file_upload_${fileIndex}`,
                          staticClass: "mt-4 col-lg-9",
                        },
                        [
                          _c("MuiFileListItem", {
                            staticClass: "emobg-link-primary emobg-body-2",
                            attrs: {
                              file: {
                                src: _vm.base64WithoutName(file),
                                name: _vm.getImageName(file),
                              },
                              "delete-action": () => _vm.removeFile(fileIndex),
                            },
                          }),
                        ],
                        1
                      )
                    }),
                    _vm._l(
                      _vm.get(_vm.caseEvent, "mediaFiles", []),
                      function (file, fileIndex) {
                        return _c(
                          "ul",
                          {
                            key: `file_created_${fileIndex}`,
                            staticClass: "mt-4 col-lg-9",
                          },
                          [
                            _c("MuiFileListItem", {
                              staticClass: "emobg-link-primary emobg-body-2",
                              attrs: {
                                file: {
                                  src: file.url,
                                  name: file.name,
                                },
                                "delete-action": () =>
                                  _vm.removeFile(fileIndex, true),
                              },
                            }),
                          ],
                          1
                        )
                      }
                    ),
                    _c("hr", {
                      staticClass:
                        "emobg-border-top-2 emobg-border-color-ground-light my-4",
                    }),
                    _vm.isEditing
                      ? _c("CommentsList", {
                          attrs: {
                            comments: _vm.comments,
                            "is-loading": _vm.areCommentsLoading,
                            callback: _vm.fetchCaseComments,
                            "entity-uuid": _vm.caseEvent.uuid,
                            entity: "case_event",
                            "data-test-id": "comments",
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "header",
                                fn: function () {
                                  return [
                                    _c(
                                      "h2",
                                      { staticClass: "emobg-font-weight-bold" },
                                      [_vm._v(" Case comments ")]
                                    ),
                                  ]
                                },
                                proxy: true,
                              },
                            ],
                            null,
                            false,
                            24772580
                          ),
                        })
                      : _vm._e(),
                  ],
                  2
                ),
                _c(
                  "div",
                  {
                    staticClass:
                      "col-12 col-md-5 p-3 emobg-background-color-ground-lightest",
                  },
                  [
                    _c("h2", { staticClass: "emobg-font-weight-bold" }, [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$t("Common.Descriptions.management_element", {
                              element: _vm.upperFirst(
                                _vm.$t("Common.Business.case")
                              ),
                            })
                          ) +
                          " "
                      ),
                    ]),
                    _c("hr", {
                      staticClass:
                        "emobg-border-top-2 emobg-border-color-ground-light my-3",
                    }),
                    _vm.caseEvent.cityName
                      ? _c("div", { staticClass: "pb-4" }, [
                          _c(
                            "label",
                            {
                              staticClass:
                                "d-block emobg-font-small pl-1 pb-1 emobg-font-weight-bold emobg-color-ink",
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.upperFirst(
                                      _vm.$t("Common.Business.city")
                                    )
                                  ) +
                                  " "
                              ),
                            ]
                          ),
                          _c("span", { staticClass: "pl-1 emobg-font-small" }, [
                            _vm._v(" " + _vm._s(_vm.caseEvent.cityName) + " "),
                          ]),
                        ])
                      : _vm._e(),
                    _vm.caseEvent.reportingUser
                      ? _c("div", { staticClass: "pb-4" }, [
                          _c(
                            "label",
                            {
                              staticClass:
                                "d-block emobg-font-small pb-1 pl-1 emobg-font-weight-bold emobg-color-ink",
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.upperFirst(
                                      _vm.$t(
                                        "Common.ActionsFinished.reported_by"
                                      )
                                    )
                                  ) +
                                  " "
                              ),
                            ]
                          ),
                          _c(
                            "a",
                            {
                              staticClass:
                                "emobg-link color-main pl-1 emobg-font-small",
                              attrs: {
                                href: `/user-company/users/${_vm.get(
                                  _vm.caseEvent,
                                  "reportingUser.uuid"
                                )}`,
                                target: "_blank",
                              },
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.get(
                                      _vm.caseEvent,
                                      "reportingUser.firstName"
                                    )
                                  ) +
                                  " " +
                                  _vm._s(
                                    _vm.get(
                                      _vm.caseEvent,
                                      "reportingUser.lastName"
                                    )
                                  ) +
                                  " "
                              ),
                            ]
                          ),
                        ])
                      : _vm._e(),
                    _vm.areInputsReady
                      ? _c("MuiAlgoliaSelect", {
                          staticClass: "w-100 pb-4",
                          attrs: {
                            "results-per-page": 50,
                            filters: _vm.getOperatorFilter({
                              attribute: "cs_operators.id",
                            }),
                            title: (result) =>
                              `${result.user.first_name} ${
                                result.user.last_name
                              } - ${
                                result.user ? result.user.email : "[No email]"
                              }`,
                            label: _vm.upperFirst(
                              _vm.$t("Common.Descriptions.assignee")
                            ),
                            index: _vm.ALGOLIA_INDEXES.agents,
                            placeholder: _vm.$t(
                              "Common.Actions.select_element",
                              { element: _vm.$t("Common.Business.agent") }
                            ),
                            name: "agent_id",
                            "path-value": "user.uuid",
                          },
                          model: {
                            value: _vm.inputs.assigneeUserUuid,
                            callback: function ($$v) {
                              _vm.$set(_vm.inputs, "assigneeUserUuid", $$v)
                            },
                            expression: "inputs.assigneeUserUuid",
                          },
                        })
                      : _vm._e(),
                    _c("MuiSelect", {
                      staticClass: "w-100 mb-3",
                      attrs: {
                        label: _vm.upperFirst(
                          _vm.$t("Common.Descriptions.status")
                        ),
                        options: _vm.map(_vm.CASE_STATUS, (status) => ({
                          label: _vm.upperFirst(status),
                          value: status,
                        })),
                        name: "status",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "selected",
                          fn: function ({ item }) {
                            return [
                              _c("ui-badge", {
                                attrs: {
                                  text: item.label,
                                  color: _vm.casesStatusColors[item.value],
                                },
                              }),
                            ]
                          },
                        },
                        {
                          key: "item",
                          fn: function ({ item }) {
                            return [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "w-100 d-flex align-items-center justify-content-between",
                                },
                                [
                                  _c(
                                    "span",
                                    {
                                      staticClass: "emobg-font-weight-semibold",
                                    },
                                    [_vm._v(_vm._s(item.label))]
                                  ),
                                  _c("ui-badge", {
                                    attrs: {
                                      color: _vm.casesStatusColors[item.value],
                                      circle: "",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]
                          },
                        },
                      ]),
                      model: {
                        value: _vm.inputs.status,
                        callback: function ($$v) {
                          _vm.$set(_vm.inputs, "status", $$v)
                        },
                        expression: "inputs.status",
                      },
                    }),
                    _c("MuiSelect", {
                      staticClass: "w-100 mb-1",
                      attrs: {
                        label: _vm.upperFirst(
                          _vm.$t("Common.Descriptions.priority")
                        ),
                        options: _vm.map(
                          _vm.ZENDESK_PRIORITIES,
                          (priority) => ({
                            label: _vm.upperFirst(priority),
                            value: priority,
                          })
                        ),
                        name: "priority",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "selected",
                          fn: function ({ item }) {
                            return [
                              _c("ui-badge", {
                                attrs: {
                                  text: item.label,
                                  color: _vm.PRIORITIES_COLOR[item.value],
                                },
                              }),
                            ]
                          },
                        },
                        {
                          key: "item",
                          fn: function ({ item }) {
                            return [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "w-100 d-flex align-items-center justify-content-between",
                                },
                                [
                                  _c(
                                    "span",
                                    {
                                      staticClass: "emobg-font-weight-semibold",
                                    },
                                    [_vm._v(_vm._s(item.label))]
                                  ),
                                  _c("ui-badge", {
                                    attrs: {
                                      color: _vm.PRIORITIES_COLOR[item.value],
                                      circle: "",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]
                          },
                        },
                      ]),
                      model: {
                        value: _vm.inputs.priority,
                        callback: function ($$v) {
                          _vm.$set(_vm.inputs, "priority", $$v)
                        },
                        expression: "inputs.priority",
                      },
                    }),
                    _c("hr", {
                      staticClass:
                        "emobg-border-top-2 emobg-border-color-ground-light my-4",
                    }),
                    !_vm.isEditing ||
                    _vm.get(_vm.caseEvent, "externalTickets.zendesk")
                      ? _c(
                          "label",
                          {
                            staticClass:
                              "d-block emobg-font-small pb-1 emobg-font-weight-bold pl-0 emobg-color-ink",
                          },
                          [_vm._v(" Zendesk ")]
                        )
                      : _vm._e(),
                    !_vm.isEditing
                      ? _c("ui-checkbox", {
                          staticClass: "mb-4",
                          attrs: {
                            checked: _vm.inputs.externalTicketingSystems,
                            caption: _vm.$t("Common.Actions.create_element", {
                              element: _vm.$t("Common.Business.ticket"),
                            }),
                          },
                          on: {
                            changevalue: ({ detail }) =>
                              (_vm.inputs.externalTicketingSystems = detail),
                          },
                        })
                      : _vm.get(_vm.caseEvent, "externalTickets.zendesk")
                      ? _c(
                          "a",
                          {
                            staticClass:
                              "emobg-link color-main d-inline-block pl-1 pb-3 emobg-font-small",
                            attrs: {
                              target: "_blank",
                              href: _vm.get(
                                _vm.caseEvent,
                                "externalTickets.zendesk.link"
                              ),
                            },
                          },
                          [
                            _vm._v(
                              " #" +
                                _vm._s(
                                  _vm.get(
                                    _vm.caseEvent,
                                    "externalTickets.zendesk.id"
                                  )
                                ) +
                                " "
                            ),
                          ]
                        )
                      : _vm._e(),
                    _c(
                      "label",
                      {
                        staticClass:
                          "d-block emobg-font-small pb-1 emobg-font-weight-bold pl-0 emobg-color-ink",
                      },
                      [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$t("Common.Actions.share_with_element", {
                                element: _vm.$t("Common.Business.agents"),
                              })
                            ) +
                            " "
                        ),
                      ]
                    ),
                    _vm.areInputsReady
                      ? _c("MuiAlgoliaSelect", {
                          staticClass: "w-100 pb-4 m-0",
                          attrs: {
                            filters: _vm.filterShareAgents,
                            title: (result) =>
                              `${result.user.first_name} ${result.user.last_name} - ${result.user.email}`,
                            placeholder: _vm.$t(
                              "Common.Actions.select_element",
                              { element: _vm.$t("Common.Business.agents") }
                            ),
                            multiple: "",
                            index: _vm.ALGOLIA_INDEXES.agents,
                            "path-value": "user.uuid",
                            name: "agents",
                          },
                          on: { selected: (items) => (_vm.agents = items) },
                          model: {
                            value: _vm.userUuids,
                            callback: function ($$v) {
                              _vm.userUuids = $$v
                            },
                            expression: "userUuids",
                          },
                        })
                      : _vm._e(),
                    _c(
                      "label",
                      {
                        staticClass:
                          "d-block emobg-font-small pb-1 emobg-font-weight-bold pl-0 emobg-color-ink",
                      },
                      [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$t("Common.Actions.share_element", {
                                element: _vm.$t("Common.Descriptions.history"),
                              })
                            ) +
                            " "
                        ),
                      ]
                    ),
                    _c("CaseHistoryComponent", {
                      attrs: {
                        items: _vm.caseEvent.sharedWith,
                        "item-template": _vm.sharedWithTemplate,
                      },
                    }),
                    _c("hr", {
                      staticClass:
                        "emobg-border-top-2 emobg-border-color-ground-light my-4",
                    }),
                    _c(
                      "label",
                      {
                        staticClass:
                          "d-block emobg-font-small pb-1 emobg-font-weight-bold emobg-color-ink",
                      },
                      [_vm._v(" Case history ")]
                    ),
                    _c("CaseHistoryComponent", {
                      attrs: {
                        items: _vm.caseEventAuditData,
                        "item-template": _vm.auditTemplate,
                      },
                    }),
                  ],
                  1
                ),
              ]),
              _c(
                "div",
                {
                  staticClass:
                    "emobg-border-top-1 emobg-border-color-ground-light p-4",
                },
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "d-flex align-items-center justify-content-end",
                    },
                    [
                      _c(
                        "ui-button",
                        {
                          staticClass: "mr-2",
                          attrs: {
                            color: _vm.GRAYSCALE.inkLight,
                            face: _vm.FACES.text,
                          },
                          on: { clickbutton: _vm.redirect },
                        },
                        [
                          _vm._v(
                            " " + _vm._s(_vm.$t("Common.Actions.cancel")) + " "
                          ),
                        ]
                      ),
                      _c(
                        "ui-button",
                        {
                          attrs: { disabled: _vm.isActionDisabled },
                          on: { clickbutton: _vm.doRequest },
                        },
                        [_vm._v(" " + _vm._s(_vm.actionText) + " ")]
                      ),
                    ],
                    1
                  ),
                ]
              ),
            ]),
          ]),
        ],
        1
      ),
      _vm.isDeleteMediaModalOpen
        ? _c("DeleteCaseMediaModal", {
            attrs: {
              file: _vm.currentFileToDelete,
              "on-delete": _vm.onDeleteMediaCallback,
            },
            on: { closeModal: () => (_vm.isDeleteMediaModalOpen = false) },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }