<script>
export default {
  name: 'CaseEventsView',
};
</script>

<template>
  <div class="CaseEventsView">
    <RouterView />
  </div>
</template>
