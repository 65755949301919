import { render, staticRenderFns } from "./CaseHistoryComponent.vue?vue&type=template&id=aa7be3a4&"
import script from "./CaseHistoryComponent.vue?vue&type=script&lang=js&"
export * from "./CaseHistoryComponent.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/app/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('aa7be3a4')) {
      api.createRecord('aa7be3a4', component.options)
    } else {
      api.reload('aa7be3a4', component.options)
    }
    module.hot.accept("./CaseHistoryComponent.vue?vue&type=template&id=aa7be3a4&", function () {
      api.rerender('aa7be3a4', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/domains/CasesServices/CaseEvents/Detail/components/CaseHistory/CaseHistoryComponent.vue"
export default component.exports