<script>
export default {
  name: 'CasesServicesView',
};
</script>

<template>
  <div class="CasesServicesView">
    <RouterView />
  </div>
</template>
