var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "RelatesToComponent" },
    [
      _c("div", { staticClass: "row" }, [
        _c(
          "div",
          { staticClass: "col-12 col-sm-3 col-md-4 col-lg-3" },
          [
            _c("MuiSelect", {
              staticClass: "w-100 mb-2 mb-sm-0",
              attrs: {
                placeholder: _vm.upperFirst(_vm.$t("Common.Business.entity")),
                options: _vm.entitiesList,
                name: "entity",
              },
              on: { change: () => (_vm.currentItem.entityUuid = "") },
              model: {
                value: _vm.currentItem.entity,
                callback: function ($$v) {
                  _vm.$set(_vm.currentItem, "entity", $$v)
                },
                expression: "currentItem.entity",
              },
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "col-12 col-sm-7 col-md-8 col-lg min-width--none" },
          [
            _c("MuiAlgoliaSelect", {
              key: _vm.currentItem.entity,
              staticClass: "w-100 mb-2 mb-sm-0",
              attrs: {
                filters: _vm.filters || "",
                title: _vm.title,
                index: _vm.index,
                disabled: !_vm.currentItem.entity,
                placeholder: _vm.upperFirst(
                  _vm.$t("Common.Comparisons.relation")
                ),
                "path-value": "uuid",
                name: "entityUuid",
              },
              on: { selected: _vm.fillCurrentItem },
              model: {
                value: _vm.itemModel,
                callback: function ($$v) {
                  _vm.itemModel = $$v
                },
                expression: "itemModel",
              },
            }),
          ],
          1
        ),
        _c(
          "div",
          {
            staticClass:
              "d-flex justify-content-end col-12 col-sm-2 col-md-12 col-lg-auto",
          },
          [
            _c(
              "ui-button",
              {
                staticClass: "wmin-initial font-s",
                attrs: {
                  disabled: !_vm.currentItem.entityUuid,
                  face: _vm.FACES.outline,
                },
                on: { clickbutton: _vm.addCurrentItemToItems },
              },
              [_vm._v(" " + _vm._s(_vm.$t("Common.Actions.add")) + " ")]
            ),
          ],
          1
        ),
      ]),
      _vm._l(_vm.items, function (item, itemIndex) {
        return _c(
          "div",
          {
            key: item.entityUuid,
            staticClass: "row align-items-center emobg-font-small pl-1",
          },
          [
            _c("div", {
              staticClass: "col-3 flex-fill text-ellipsis",
              class: {
                "color-danger": !item.algoliaResult,
              },
              attrs: { title: _vm.get(_vm.entities[item.entity], "label") },
              domProps: {
                innerHTML: _vm._s(_vm.get(_vm.entities[item.entity], "label")),
              },
            }),
            item.algoliaResult
              ? _c("div", {
                  staticClass: "col-7 flex-fill text-ellipsis",
                  attrs: { title: _vm.templateToShow(item, "templateSelect") },
                  domProps: {
                    innerHTML: _vm._s(_vm.templateToShow(item, "templateList")),
                  },
                })
              : _c("div", { staticClass: "col-7 color-danger" }, [
                  _vm._v(" " + _vm._s(`${item.entityUuid} not found`) + " "),
                ]),
            _c(
              "div",
              { staticClass: "col-2 d-flex justify-content-end" },
              [
                _c(
                  "ui-button",
                  {
                    attrs: {
                      disabled: !!item.autoLinked,
                      face: _vm.FACES.text,
                      square: "",
                    },
                    on: {
                      clickbutton: function ($event) {
                        return _vm.removeItem(itemIndex)
                      },
                    },
                  },
                  [
                    _c("ui-icon", {
                      attrs: {
                        icon: _vm.ICONS.trash,
                        size: _vm.ICONS_SIZES.small,
                        square: "",
                      },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
          ]
        )
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }