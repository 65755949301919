import { capitalize } from 'lodash';
import { companyProfileUrl } from '@domains/CRM/Companies/utils/companyProfileUrl';
const sanitize = string => capitalize(string.replace(/_/g, ' '));
const generateLink = (url, text) => {
  const linkClass = 'text-decoration-none color-main';
  return `<a class="${linkClass}" href="${url}" target="_blank">${text}</a>`;
};

const generateInvoice = value => {
  const link = generateLink(`/invoicing/invoices/${value.uuid}/summary/details`,
    `${value.serie} ${value.number}`);
  return `${link} - (${value.created_at})`;
};

export const entitiesConfiguration = operatorsFilter => ({
  booking: {
    label: 'Booking',
    index: 'cs_bookings',
    templateSelect: value => `#${value.id} - (${value.start})`,
    templateList: value => {
      const link = generateLink(`/carsharing/bookings/${value.uuid}`, `#${value.id}`);
      return `${link} - (${value.start})`;
    },
    filters: operatorsFilter({ attribute: 'cs_operator_fk' }),
  },
  case: {
    label: 'Case',
    index: 'case_events',
    templateSelect: value => `#${value.id} - ${sanitize(value.case_type_internal_name)}`,
    templateList: value => {
      const link = generateLink(`/cases-service/case-events/${value.uuid}`, `#${value.id}`);
      return `${link} - ${sanitize(value.case_type_internal_name)}`;
    },
    filters: operatorsFilter({ attribute: 'cs_operator_id' }),
  },
  company: {
    label: 'Company',
    index: 'companies',
    templateSelect: value => `${value.commercial_name} - ${value.email}`,
    templateList: value => {
      const link = generateLink(companyProfileUrl(value.uuid), `${value.commercial_name}`);
      return `${link} - ${value.email}`;
    },
    filters: operatorsFilter({ attribute: 'cs_operator_fk' }),
  },
  damage: {
    label: 'Damage',
    index: 'vehicle_damages',
    templateSelect: value => `#${value.id} - ${sanitize(value.damage_type)}`,
    templateList: value => {
      const link = generateLink(`/fleet/vehicles/${value.vehicle.uuid}/vehicle-damages/${value.uuid}`, `#${value.id}`);
      return `${link} - ${sanitize(value.damage_type)}`;
    },
    filters: operatorsFilter({ attribute: 'vehicle.cs_operator_fk' }),
  },
  delivery_note: {
    label: 'Delivery note',
    index: 'delivery_notes',
    templateSelect: value => `${value.serie} ${value.number} - (${value.created_at})`,
    templateList: generateInvoice,
    filters: operatorsFilter({ attribute: 'cs_operator_fk' }),
  },
  invoice: {
    label: 'Invoice',
    index: 'invoices',
    templateSelect: value => `${value.serie} ${value.number} - (${value.created_at})`,
    templateList: generateInvoice,
    filters: operatorsFilter({ attribute: 'cs_operator_fk' }),
  },
  location: {
    label: 'Location',
    index: 'locations',
    templateSelect: value => `${value.name} - ${value.neighborhood}`,
    templateList: value => {
      const link = generateLink(`/fleet/locations/${value.uuid}`, `${value.name}`);
      return `${link} - ${value.neighborhood}`;
    },
    filters: operatorsFilter({ attribute: 'cs_operator_fk' }),
  },
  user: {
    label: 'User',
    index: 'users',
    templateSelect: value => `${value.first_name} ${value.last_name} - ${value.email}`,
    templateList: value => {
      const link = generateLink(`/user-company/users/${value.uuid}`, `${value.first_name} ${value.last_name}`);
      return `${link} - ${value.email}`;
    },
    filters: operatorsFilter({ attribute: 'cs_operator_fk', index: 'users' }),
  },
  vehicle: {
    label: 'Vehicle',
    index: 'vehicles',
    templateSelect: value => `${value.vehicle_brand} - ${value.vehicle_model} - ${value.license_plate}`,
    templateList: value => {
      const link = generateLink(`/fleet/vehicles/${value.uuid}/details/setup`, `${value.license_plate}`);
      return `${value.vehicle_brand} - ${value.vehicle_model} - ${link}`;
    },
    filters: operatorsFilter({ attribute: 'cs_operator_fk', index: 'vehicles' }),
  },
});
