<script>
import { mapState } from 'vuex';
import DOMAINS_MODEL from '@domains/DOMAINS_MODEL';
import { base64WithoutName, getValue, isStringValue } from '@emobg/web-utils';
import {
  CancelButton,
  DeleteButton,
  DeleteModalBodyComponent,
  GenericModalComponent,
} from '@/components';

export default {
  name: 'DeleteCaseMediaModal',
  components: {
    CancelButton,
    DeleteButton,
    DeleteModalBodyComponent,
    GenericModalComponent,
  },
  props: {
    file: {
      type: Object,
      required: true,
    },
    onDelete: {
      type: Function,
      default: () => {},
    },
  },
  computed: {
    ...mapState(DOMAINS_MODEL.casesServices.caseEvents.detail, {
      isLoading: state => state.media.STATUS.LOADING,
    }),
    isNewFile() {
      return isStringValue(getValue(this.file, 'media'));
    },
    url() {
      return this.isNewFile ? this.base64WithoutName(this.file.media) : getValue(this.file, 'media.url', '');
    },
    isImage() {
      return !(this.isNewFile ? this.url.indexOf('/pdf') > -1 : this.url.indexOf('.pdf') > -1);
    },
  },
  methods: {
    base64WithoutName,
    async deleteMedia() {
      const target = this.isNewFile ? 'inputs.caseFiles' : 'caseEvent.mediaFiles';
      await this.onDelete(target, this.file.index);
      this.$emit('closeModal');
    },
  },
};
</script>

<template>
  <GenericModalComponent
    :header="null"
    :size="SIZES.medium"
    class="DeleteCaseMediaModal"
    v-on="$listeners"
  >
    <template #body>
      <DeleteModalBodyComponent :title="$t('Common.Questions.delete_element', { element: $t('Common.Business.file')})" />
      <div class="d-flex align-items-center justify-content-center mt-3 overflow-auto">
        <img
          v-if="isImage && url"
          :src="url"
          class="w-auto"
          style="max-height: 200px;"
          alt="case"
        >
        <ui-icon
          v-else
          :icon="ICONS.document"
          :size="ICONS_SIZES.xLarge"
          class="mb-3"
        />
      </div>
    </template>
    <template #footer>
      <CancelButton @click="$emit('closeModal')" />
      <DeleteButton
        :loading="isLoading"
        @click="deleteMedia"
      />
    </template>
  </GenericModalComponent>
</template>
