var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "GenericModalComponent",
    _vm._g(
      {
        staticClass: "DeleteCaseMediaModal",
        attrs: { header: null, size: _vm.SIZES.medium },
        scopedSlots: _vm._u([
          {
            key: "body",
            fn: function () {
              return [
                _c("DeleteModalBodyComponent", {
                  attrs: {
                    title: _vm.$t("Common.Questions.delete_element", {
                      element: _vm.$t("Common.Business.file"),
                    }),
                  },
                }),
                _c(
                  "div",
                  {
                    staticClass:
                      "d-flex align-items-center justify-content-center mt-3 overflow-auto",
                  },
                  [
                    _vm.isImage && _vm.url
                      ? _c("img", {
                          staticClass: "w-auto",
                          staticStyle: { "max-height": "200px" },
                          attrs: { src: _vm.url, alt: "case" },
                        })
                      : _c("ui-icon", {
                          staticClass: "mb-3",
                          attrs: {
                            icon: _vm.ICONS.document,
                            size: _vm.ICONS_SIZES.xLarge,
                          },
                        }),
                  ],
                  1
                ),
              ]
            },
            proxy: true,
          },
          {
            key: "footer",
            fn: function () {
              return [
                _c("CancelButton", {
                  on: {
                    click: function ($event) {
                      return _vm.$emit("closeModal")
                    },
                  },
                }),
                _c("DeleteButton", {
                  attrs: { loading: _vm.isLoading },
                  on: { click: _vm.deleteMedia },
                }),
              ]
            },
            proxy: true,
          },
        ]),
      },
      _vm.$listeners
    )
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }