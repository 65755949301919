var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass:
        "CaseFileUploaderComponent d-inline-block position-relative cursor-pointer",
    },
    [
      _c("input", {
        ref: "input",
        staticClass:
          "CaseFileUploaderComponent__file w-100 h-100 cursor-pointer",
        attrs: {
          accept: _vm.accept,
          disabled: _vm.disabled,
          title: "",
          multiple: "",
          type: "file",
        },
        on: { change: _vm.change },
      }),
      _c(
        "ui-button",
        { attrs: { disabled: _vm.disabled, face: _vm.FACES.outline } },
        [_vm._v(" " + _vm._s(_vm.$t("Common.Actions.upload")) + " ")]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }