<script>
export default {
  name: 'CaseHistoryComponent',
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    itemTemplate: {
      type: Function,
      default: item => item,
    },
    limit: {
      type: Number,
      default: 3,
    },
  },
  data() {
    return {
      isShowMoreVisible: false,
    };
  },
  computed: {
    itemsFiltered() {
      return this.isShowMoreVisible && this.items.length > this.limit ? this.items : this.items.slice(0, 3);
    },
    actionText() {
      return this.isShowMoreVisible ? '- Show less' : '+ Show more';
    },
  },
};
</script>
<template>
  <div class="CaseHistoryComponent emobg-font-small">
    <div v-if="items.length">
      <ul
        v-for="(item, index) in itemsFiltered"
        :key="index"
      >
        <li
          class="pb-2 mb-1"
          v-html="itemTemplate(item)"
        />
      </ul>
      <ui-button
        v-if="items.length > limit"
        :face="FACES.text"
        class="px-0 wmin-initial height--initial"
        @clickbutton="isShowMoreVisible = !isShowMoreVisible"
      >
        {{ actionText }}
      </ui-button>
    </div>
    <div v-else>
      {{ FALLBACK_MESSAGE.dash }}
    </div>
  </div>
</template>
