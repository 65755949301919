var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "CaseHistoryComponent emobg-font-small" }, [
    _vm.items.length
      ? _c(
          "div",
          [
            _vm._l(_vm.itemsFiltered, function (item, index) {
              return _c("ul", { key: index }, [
                _c("li", {
                  staticClass: "pb-2 mb-1",
                  domProps: { innerHTML: _vm._s(_vm.itemTemplate(item)) },
                }),
              ])
            }),
            _vm.items.length > _vm.limit
              ? _c(
                  "ui-button",
                  {
                    staticClass: "px-0 wmin-initial height--initial",
                    attrs: { face: _vm.FACES.text },
                    on: {
                      clickbutton: function ($event) {
                        _vm.isShowMoreVisible = !_vm.isShowMoreVisible
                      },
                    },
                  },
                  [_vm._v(" " + _vm._s(_vm.actionText) + " ")]
                )
              : _vm._e(),
          ],
          2
        )
      : _c("div", [_vm._v(" " + _vm._s(_vm.FALLBACK_MESSAGE.dash) + " ")]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }