var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "CopyToClipboardComponent" }, [
    _c(
      "span",
      { staticClass: "cursor-pointer", on: { click: _vm.copyCaseContent } },
      [
        _vm._v(
          " " +
            _vm._s(
              _vm.$t("Common.Actions.copy_element_clipboard", {
                element: _vm.$t("Common.Descriptions.summary_element", {
                  element: _vm.$t("Common.Business.case"),
                }),
              })
            ) +
            " "
        ),
      ]
    ),
    _c("textarea", {
      ref: "copyTextarea",
      staticStyle: { position: "fixed", width: "0", height: "0", opacity: "0" },
      domProps: { value: _vm.copyContentText() },
    }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }