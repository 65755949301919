<script>
import isNil from 'lodash/isNil';
import { mapGetters } from 'vuex';
import { MuiAlgoliaList } from '@emobg/motion-ui';

import DOMAINS_MODEL from '@domains/DOMAINS_MODEL';
import { PageView } from '@/components';
import ALGOLIA_INDEXES from '@/constants/algoliaIndexes';
import { attributesToRetrieve, caseEventsFacets, contentCells } from '../../const/eventsListContentCells';

const CASE_ENTITIES = [
  'booking',
  'user',
  'vehicle',
];

export default {
  name: 'CaseEventsListView',
  components: {
    MuiAlgoliaList,
    PageView,
  },

  props: {
    /**
     * With the entity and entity ID we can reuse this list into any necessary page,
     * if these values are undefined
     * the list will be used as a general list filtering for any type of entity.
     */
    entity: {
      type: String,
      default: null,
      validator: value => CASE_ENTITIES.indexOf(value) !== -1,
    },
    entityId: {
      type: Number,
      default: null,
    },
  },

  computed: {
    ...mapGetters(DOMAINS_MODEL.app.userAccount, [
      'getOperatorFilter',
      'operatorTimezone',
    ]),
    isFilteredByEntity() {
      return !(isNil(this.entity) && isNil(this.entityId));
    },
    caseEventsFilters() {
      let filters = '';

      if (this.isFilteredByEntity) {
        filters += ` main_entity:${this.entity}`;

        let entityPropAlgolia = '';
        switch (this.entity) {
          case 'booking':
          case 'vehicle':
            entityPropAlgolia = `${this.entity}_fk`;
            break;
          default:
            entityPropAlgolia = `${this.entity}_id`;
        }
        filters += ` AND ${entityPropAlgolia}:${this.entityId}`;
      } else {
        filters += this.getOperatorFilter({ attribute: 'cs_operator_id' });
      }
      return filters;
    },
    contentCells() {
      return contentCells(this.operatorTimezone);
    },
    caseEventsFacets() {
      return caseEventsFacets(this.operatorTimezone);
    },
  },
  created() {
    this.ALGOLIA_INDEXES = ALGOLIA_INDEXES;
    this.attributesToRetrieve = attributesToRetrieve;
  },
};
</script>

<template>
  <PageView class="CaseEventsListView">
    <h1 class="d-flex mb-2">
      Cases
      <ui-tooltip
        no-arrow
        class="d-flex ml-1"
        :tooltip="$t('CasesServices.Common.cases_list_tooltip')"
      >
        <ui-icon
          :icon="ICONS.infoFull"
          :size="ICONS_SIZES.small"
          class="emobg-color-ink-light emobg-color-ink-hover"
          data-test-id="icon"
        />
      </ui-tooltip>
    </h1>
    <MuiAlgoliaList
      :facets="caseEventsFacets"
      :filters="caseEventsFilters"
      :export-columns="contentCells"
      :table-config="contentCells"
      :query-parameters="{ attributesToRetrieve }"
      :index="ALGOLIA_INDEXES.caseEvents"
      is-export-enabled
    />
  </PageView>
</template>
