<script>
import forEach from 'lodash/forEach';
import { getBase64 } from '@emobg/web-utils';

export default {
  name: 'CaseFileUploaderComponent',
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    onChange: {
      type: Function,
      default: () => {},
    },
    accept: {
      type: String,
      default: null,
    },
  },
  methods: {
    async change($event) {
      this.$props.onChange($event, await this.transformToCasesBase64(Array.from($event.target.files)));
      this.$refs.input.value = null;
    },
    transformToCasesBase64(files) {
      if (files && files.length) {
        const base64Promises = [];

        forEach(files, file => {
          base64Promises.push(getBase64(file, { replaceHeader: false, types: ['png', 'jpg', 'jpeg', 'pdf'] }));
        });

        return Promise.all(base64Promises);
      }

      return [];
    },
  },
};
</script>

<template>
  <div class="CaseFileUploaderComponent d-inline-block position-relative cursor-pointer">
    <input
      ref="input"
      :accept="accept"
      :disabled="disabled"
      title=""
      multiple
      type="file"
      class="CaseFileUploaderComponent__file w-100 h-100 cursor-pointer"
      @change="change"
    >
    <ui-button
      :disabled="disabled"
      :face="FACES.outline"
    >
      {{ $t('Common.Actions.upload') }}
    </ui-button>
  </div>
</template>

<style lang="scss">
  .CaseFileUploaderComponent {
    // TODO move to motion
    input[type="file"]::-webkit-file-upload-button {
      display: none;
    }

    &__file {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;
      opacity: 0;
    }
  }
</style>
