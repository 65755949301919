var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "PageView",
    { staticClass: "CaseEventsListView" },
    [
      _c(
        "h1",
        { staticClass: "d-flex mb-2" },
        [
          _vm._v(" Cases "),
          _c(
            "ui-tooltip",
            {
              staticClass: "d-flex ml-1",
              attrs: {
                "no-arrow": "",
                tooltip: _vm.$t("CasesServices.Common.cases_list_tooltip"),
              },
            },
            [
              _c("ui-icon", {
                staticClass: "emobg-color-ink-light emobg-color-ink-hover",
                attrs: {
                  icon: _vm.ICONS.infoFull,
                  size: _vm.ICONS_SIZES.small,
                  "data-test-id": "icon",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c("MuiAlgoliaList", {
        attrs: {
          facets: _vm.caseEventsFacets,
          filters: _vm.caseEventsFilters,
          "export-columns": _vm.contentCells,
          "table-config": _vm.contentCells,
          "query-parameters": {
            attributesToRetrieve: _vm.attributesToRetrieve,
          },
          index: _vm.ALGOLIA_INDEXES.caseEvents,
          "is-export-enabled": "",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }