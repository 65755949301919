<script>
import each from 'lodash/each';
import get from 'lodash/get';
import trim from 'lodash/trim';
import { FALLBACK_MESSAGE, sentenceCase } from '@emobg/web-utils';
import { mapActions } from 'vuex';
import DOMAINS_MODEL from '@domains/DOMAINS_MODEL';

export default {
  name: 'CopyToClipboardComponent',
  props: {
    title: {
      type: String,
      default: '',
    },
    user: {
      type: Object,
      default: null,
    },
    booking: {
      type: Object,
      default: null,
    },
    vehicle: {
      type: Object,
      default: null,
    },
    reportingUser: {
      type: Object,
      default: null,
    },
    caseEvent: {
      type: Object,
      default: undefined,
    },
  },
  methods: {
    parseObject(object) {
      let stringToReturn = '';
      each(object, (value, key) => {
        stringToReturn += `\r${sentenceCase(key)}: ${value}`;
      });

      return stringToReturn;
    },
    copyContentText() {
      const objectToTransform = {
        user: this.user ? `${this.user.firstName} ${this.user.lastName}` : FALLBACK_MESSAGE.dash,
        bookingNumber: this.booking ? `#${this.booking.id}` : FALLBACK_MESSAGE.dash,
        vehicle: this.vehicle ? this.vehicle.licensePlate : FALLBACK_MESSAGE.dash,
        reportingUser: (this.reportingUser)
          ? `${this.reportingUser.id} - ${this.reportingUser.firstName} ${this.reportingUser.lastName}`
          : FALLBACK_MESSAGE.dash,
        caseDescription: get(this.caseEvent, 'comment') || FALLBACK_MESSAGE.dash,
        caseStatus: get(this.caseEvent, 'status') || FALLBACK_MESSAGE.dash,
      };

      return trim(`\r${this.title} ${this.parseObject(objectToTransform)}`);
    },
    copyCaseContent() {
      try {
        this.$refs.copyTextarea.select();
        document.execCommand('copy');
        this.$notify({
          message: 'Copied',
          textAction: '',
          delay: 2000,
        });
      } catch (e) {
        this.setErrorMessage(e);
      }
    },
    ...mapActions(DOMAINS_MODEL.app.messages.errors.throwError, {
      setErrorMessage: 'add',
    }),
  },
};
</script>
<template>
  <div class="CopyToClipboardComponent">
    <span
      class="cursor-pointer"
      @click="copyCaseContent"
    >
      {{ $t('Common.Actions.copy_element_clipboard', {
        element: $t('Common.Descriptions.summary_element',
                    { element: $t('Common.Business.case')})}) }}
    </span>
    <textarea
      ref="copyTextarea"
      :value="copyContentText()"
      style="position: fixed; width: 0; height: 0; opacity: 0;"
    />
  </div>
</template>
