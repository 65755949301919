import values from 'lodash/values';
import { PRIORITIES } from '../../../const/priorities';

export const ALGOLIA_INDEXES = Object.freeze({
  booking: 'cs_bookings',
  user: 'users',
  vehicle: 'vehicles',
  caseTypes: 'case_types',
  agents: 'agents',
});
export const ENTITIES = Object.freeze({
  booking: 'booking',
  user: 'user',
  vehicle: 'vehicle',
});

export const CASE_STATUS = [
  'open',
  'in progress',
  'blocked',
  'closed',
];

export const ZENDESK_PRIORITIES = values(PRIORITIES);
